/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'GlobalStyles',
        import: () => import('@stories/Widgets/Global/GlobalStyles/GlobalStyles'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Header',
        import: () => import('@stories/Widgets/Global/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Global/Footer/Footer'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SearchListing',
        import: () => import('@stories/Widgets/SearchListing/SearchListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerPageBanner',
        import: () => import('@stories/Widgets/InnerPageBanner/InnerPageBanner'),
        config: {
            hydrate: 'always', // set to always due to `display: contents;` stling on wrapping div
        },
    },
    {
        name: 'FaqAccordion',
        import: () => import('@stories/Widgets/FaqAccordion/FaqAccordion'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Split5050Content',
        import: () => import('@stories/Widgets/Split5050Content/Split5050Content'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HomepageHeroBanner',
        import: () => import('@stories/Widgets/HomepageHeroBanner/HomepageHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ArticleBanner',
        import: () => import('@stories/Widgets/ArticleBanner/ArticleBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsListing',
        import: () => import('@stories/Widgets/NewsListing/NewsListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeaturedPromotionBanner',
        import: () => import('@stories/Widgets/FeaturedPromotionBanner/FeaturedPromotionBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FullWidthSignpost',
        import: () => import('@stories/Widgets/FullWidthSignpost/FullWidthSignpost'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerPageNavigation',
        import: () => import('@stories/Widgets/InnerPageNavigation/InnerPageNavigation'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'EmbeddedForm',
        import: () => import('@stories/Widgets/EmbeddedForm/EmbeddedForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CareerListing',
        import: () => import('@stories/Widgets/CareerListing/CareerListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AuditListing',
        import: () => import('@stories/Widgets/AuditListing/AuditListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SignpostSplitContent',
        import: () => import('@stories/Widgets/SignpostSplitContent/SignpostSplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SignpostCards',
        import: () => import('@stories/Widgets/SignpostCards/SignpostCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SignpostDetailCards',
        import: () => import('@stories/Widgets/SignpostDetailCards/SignpostDetailCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GlossaryListing',
        import: () => import('@stories/Widgets/GlossaryListing/GlossaryListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PublicationListing',
        import: () => import('@stories/Widgets/PublicationListing/PublicationListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeaturesList',
        import: () => import('@stories/Widgets/FeaturesList/FeaturesList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StaffProfileCards',
        import: () => import('@stories/Widgets/StaffProfileCards/StaffProfileCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventsListing',
        import: () => import('@stories/Widgets/EventsListing/EventsListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Downloads',
        import: () => import('@stories/Widgets/Downloads/Downloads'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ExternalLinkListing',
        import: () => import('@stories/Widgets/ExternalLinkListing/ExternalLinkListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TestimonialCarousel',
        import: () => import('@stories/Widgets/TestimonialCarousel/TestimonialCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Timeline',
        import: () => import('@stories/Widgets/Timeline/Timeline'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventCalendar',
        import: () => import('@stories/Widgets/EventCalendar/EventCalendar'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MembershipPriceList',
        import: () => import('@stories/Widgets/MembershipPriceList/MembershipPriceList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SideTabbedContent',
        import: () => import('@stories/Widgets/SideTabbedContent/SideTabbedContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventsCarousel',
        import: () => import('@stories/Widgets/EventsCarousel/EventsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PublicationCarousel',
        import: () => import('@stories/Widgets/PublicationCarousel/PublicationCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'KeyFactsList',
        import: () => import('@stories/Widgets/KeyFactsList/KeyFactsList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SocialShareBanner',
        import: () => import('@stories/Widgets/SocialShareBanner/SocialShareBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ProgrammeTable',
        import: () => import('@stories/Widgets/ProgrammeTable/ProgrammeTable'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LatestNewsAndBlogs',
        import: () => import('@stories/Widgets/LatestNewsAndBlogs/LatestNewsAndBlogs'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FacultyStructure',
        import: () => import('@stories/Widgets/FacultyStructure/FacultyStructure'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventOverviewBanner',
        import: () => import('@stories/Widgets/EventOverviewBanner/EventOverviewBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AuthorProfileCards',
        import: () => import('@stories/Widgets/AuthorProfileCards/AuthorProfileCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AlertsBanner',
        import: () => import('@stories/Widgets/AlertsBanner/AlertsBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PageLinkListing',
        import: () => import('@stories/Widgets/PageLinkListing/PageLinkListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsletterSignUp',
        import: () => import('@stories/Widgets/NewsletterSignUp/NewsletterSignUp'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'USPCards',
        import: () => import('@stories/Widgets/USPCards/USPCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MediaEmbed',
        import: () => import('@stories/Widgets/MediaEmbed/MediaEmbed'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HtmlSiteMap',
        import: () => import('@stories/Widgets/HtmlSiteMap/HtmlSiteMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AiRegistryListing',
        import: () => import('@stories/Widgets/AiRegistryListing/AiRegistryListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AiRegistryCardsList',
        import: () => import('@stories/Widgets/AiRegistryCardsList/AiRegistryCardsList'),
        config: {
            hydrate: 'in-view',
        },
    },
];
